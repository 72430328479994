@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
/* body {
    padding-bottom: 20px;
} */
.viewheight{
    min-height: 100vh;
    margin-bottom: -400px;
}
.push{
    height: 400px;
}
.responsive-youtube{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}
.responsive-youtube iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}
.detailsFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    word-wrap: break-word;
    margin: 20px;
    font-weight: 600;
}

.heading{
    color: white;
    font-family: 'Playfair Display', serif;
    font-size: 55px;
    margin: 0;
}

.textfontsize{
    font-size: 20px;
}

.bestPurchaseWindow {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.07);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.9px);
    -webkit-backdrop-filter: blur(7.9px);
    border: 1px solid rgba(255, 255, 255, 0.31);
    height: 600px;
}

.BestSoftwarefont {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
.BestSoftwareHeading{
    font-family: 'Playfair Display', serif;
    font-size: 28px;
}

.bluebg {
    background-color: #293991 ;
}
.navColour{
    background-color: #293991;
}
.greenbg{
    background-color: #18a79d ;
}

.bestWidth {
    margin-left: 20%;
    margin-right: 20%;
    padding: 5px;
}

.underlined {
    text-decoration: underline;
}

.largeFont {
    font-size: 40px;
}

.borderr {
    border-style: solid;
    border-width: 2px;
    padding: 50px;
}

.logOutButton {
    position: absolute;
    top: 0;
    right: 0;
}

.padtop {
    padding: 8px;
}

.visIconStyle {
    margin-right: 15px;
}

.sortHover:hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.pointer:hover {
    cursor: pointer;
}

.link {
    text-decoration: none;
    color: black;
}

.help {
    text-decoration: none;
    color: black;
    font-size: 16px;
    padding: 8px;
}

.Autocomplete {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.floatRight {
    float: right;
}

.headers {
    display: flex;
    flex-wrap: nowrap;
}

.middlePage {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.hidden{
    display: none;
    visibility: hidden;
}

.padding {
    padding: 10px;
}

.center {
    text-align: center;
    align-items: center;
}

.flexCenter {
    display: flex;
    justify-content: center;
}

.logoSizing {
    height: 60px;
    width: 60px;
    padding: 5px;
}

.margin {
    margin: 20px;
}
.iconMargin{
    padding-bottom: 5px;
}

.navMenu {
    text-decoration: none;
    color: black;
    font-weight: 700;
}

.graphHeaders {
    background-color: rgb(199, 194, 194);
}

.blackFont {
    color: black;
}
.whiteFont{
    color: white;
}


.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 20px;
    min-height: 20px;
    color: black;
    background-color: #10ccf4;
    display: flex;
    justify-content: center;
    font-size: 12px;
}
.space-evenly{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

/* Login form */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif
}

.wrapper {
    max-width: 700px;
    min-height: 1000px;
    margin: 80px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff
}

.logo {
    width: 80px;
    margin: auto;
}

.logo img {
    width: 100%;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #5f5f5f, 0px 0px 0px 5px #ecf0f3, 8px 8px 15px #a7aaa7, -8px -8px 15px #fff
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: #555
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: #666;
    padding: 10px 15px 10px 10px
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff
}

.wrapper .form-field .fas {
    color: #555
}

.wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #03A9F4;
    color: #fff;
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1, -3px -3px 3px #fff;
    letter-spacing: 1.3px
}

.wrapper .btn:hover {
    background-color: #039BE5
}

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: #03A9F4
}

.wrapper a:hover {
    color: #039BE5
}

@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px
    }
}

/* Login form */